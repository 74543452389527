








































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import controller from '@/app/ui/controllers/PackageController'
import { Utils } from '@/app/infrastructures/misc'
import { Package } from '@/domain/entities/Package'

@Component({
  components: {
    EmptyState,
    LoadingOverlay,
  },
})
export default class PackageDetailPage extends Vue {
  controller = controller

  packageData = new Package()

  mounted(): void {
    this.controller.getPackage(this.$route.params.packageId)
    this.onSetDisplayBreadcrumb(false)
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  get packageDate(): string {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(
        <string>controller.packageData.createdAt,
        'DD MMM YYYY HH:mm Z'
      )
    )
  }

  private onSetDisplayBreadcrumb(isDisplayed: boolean): void {
    const breadcrumb = document.getElementById('breadcrumb')
    if (breadcrumb) {
      if (isDisplayed) {
        breadcrumb.style.display = 'flex'
      } else {
        breadcrumb.style.display = 'none'
      }
    }
  }

  private historyDate(date: string): string {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(<string>date, 'DD MMM YYYY HH : mm Z')
    )
  }

  private checkIsDEX(status: string): boolean {
    return status === 'DEX'
  }

  @Watch('controller.packageData')
  onPackageDataChange(data: Package): void {
    this.packageData = data
  }

  beforeDestroy(): void {
    this.onSetDisplayBreadcrumb(true)
    this.controller.setPackageData(new Package())
  }
}
